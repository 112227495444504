import Vue from 'vue'
import VueRouter from 'vue-router'
import UserLogin from '../views/login.vue'
import HomeIndex from '../views/home/index.vue'
import OrdersList from '../views/home/ordersList.vue'
import OrdersInfo from '../views/home/ordersInfo.vue'
import UserInfo from '../views/home/userInfo.vue'
import AddressList from '../views/home/addressList.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/login.html',
    name: 'login',
    component: UserLogin
  },
  {
    path: '',
    name: 'index',
    component: HomeIndex,
    meta: { 
      requireAuto: true,
      isShowHeader: true
    }
  },
  {
    path: '/ordersList.html',
    name: 'ordersList',
    component: OrdersList,
    meta: { 
      requireAuto: true,
      isShowHeader: true
    }
  },
  {
    path: '/ordersInfo.html',
    name: 'ordersInfo',
    component: OrdersInfo,
    meta: { 
      requireAuto: true,
      isShowHeader: true
    }
  },
  {
    path: '/userInfo.html',
    name: 'userInfo',
    component: UserInfo,
    meta: { 
      requireAuto: true,
      isShowHeader: true
    }
  },
  {
    path: '/addressList.html',
    name: 'addressList',
    component: AddressList,
    meta: { 
      requireAuto: true,
      isShowHeader: true
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// 路由守卫
router.beforeEach((to, from, next) => {
  //获取token
  const tokenStr = localStorage.getItem('token')
  next()
  //判断该路由是否需要登录权限
  if (to.meta.requireAuto) {
    // 判断本地是否存储token
    if (tokenStr) {
      next()
    } else {
      // 没有token
      next({
        path: '/login.html'
      })
    }
  } else {
    next()
  }
  /*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
  if (to.name === 'login') {
    if (tokenStr) {
      next({
        path: from.fullPath
      })
    } else {
      next()
    }
  } else {
    // 放行
    next()
  }
})

export default router
