<template>
  <div>
    <breadcrumbVue :breadcrumb="breadcrumb"></breadcrumbVue>
    <div class="main">

      <mainLeft></mainLeft>
      <div class="mainRight">
        <el-divider content-position="left">DNA合成</el-divider>
        <el-steps :active="stepsActive" align-center>
          <el-step title="上传表格" description="请先下载「引物合成订购表」填写之后上传"></el-step>
          <el-step title="提交订单" description="点击下方提交按钮提交订单"></el-step>
          <el-step title="解析表格" description="「引物合成订购表」解析中"></el-step>
          <el-step title="完成" description="「引物合成订购表」解析完成"></el-step>
          <el-step title="等待审核" description="等待审核"></el-step>
          <el-step title="审核通过" description="审核通过"></el-step>
        </el-steps>
        <el-upload class="upload" ref="updateFile" drag :action="action" :headers="headers" :on-remove="onRemove"
          :on-success="onSuccess" :on-exceed="onExceed" :limit="1" multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将<em>「引物合成订购表」</em>拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <!-- <el-button type="text" class="download" @click="download">下载「引物合成订购表」</el-button> -->

        <div class="download">
          <el-select @change="clickTable" v-model="tableValue" placeholder="请选择下载表格" filterable >
            <el-option :label="item.name" :value="item.id" v-for="(item,index) in table" :key="index"></el-option>
          </el-select>
          <!-- <div class="download-icon">
            <el-image style="width: 100px; height: 100px" :src="imageSrc" fit="conten"></el-image>
            <div>点此下载</div>
          </div> -->
        </div>


        <el-divider content-position="left">收货地址</el-divider>
        <div style="margin-left:50px">
          <el-radio-group v-model="addressId" @change="changeAddress">
            <el-radio :label="item.id" v-for="(item,index) in userAddress" :key="index">{{item.name}} {{item.phone}} {{item.provinceName}} {{item.cityName}} {{item.address}}</el-radio>
            <el-radio v-model="addressId" label="-1">使用新的地址</el-radio>
          </el-radio-group>

          <!-- <el-radio v-model="addressId" :label="item.id" v-for="(item,index) in userAddress" :key="index">{{item.name}} {{item.phone}} {{item.provinceName}} {{item.cityName}} {{item.address}} </el-radio> -->
          
          <!-- <el-button type="primary" @click="doNewAddress" style="margin-left:50px">使用新的地址</el-button> -->
        </div>
        
        <div class="newAddress" v-if="newAddress">
          <regions @handleChange="doNewAddress"></regions>
        </div>
        
        <el-button type="primary" class="submit" @click="submit">提交订单</el-button>
        <!-- <el-alert
      title="重新上传之前需先删除已上传的文件，否则上传无效"
      type="error"
      effect="dark"
      :closable="false"
      v-if="alert">
    </el-alert> -->
      </div>
    </div>
  </div>
</template>
<script>
import mainLeft from '../mainLeft.vue';
import regions from '@/components/regions.vue'
import breadcrumbVue from '@/components/breadcrumb.vue';
import { 
  addressList,
  createOrders 
} from "@/api/api.js";
export default {
  data() {
    return {
      imageSrc: require('@/assets/up-arrow.png'),
      newAddress: false,
      addressId:0,
      userAddress:[],
      stepsActive: 0,
      action: this.BaseUrl + '/updateImage',
      headers: { 'Authorization': localStorage.getItem('token') },
      table:[
        {
          id:1,
          name:"nmol分装订购表"
        },
        {
          id:2,
          name:"OD分装订购表"
        },
      ],
      tableValue:"",
      file: '',
      breadcrumb: [
        {
          name: 'DNA合成'
        }
      ]
    };
  },
  components: {
    mainLeft,
    regions,
    breadcrumbVue
  },
  created(){
    this.getAddressList();
  },
  methods: {
    doNewAddress(e){
      this.getAddressList(e)
      this.newAddress = false
    },
    getAddressList(e=0){
      addressList().then(res =>{
        // if(res.code == 40000){
        //     location.reload()
        // }
        this.userAddress = res.data;
        this.userAddress.forEach((item) => {
          if(e == 0){
            if(item.default == 1){
              this.addressId = item.id
            }
          }else{
            this.addressId = Number(e)
          }
          
          return item;
        })
      })
    },
    clickTable(id) {
      let v = Math.random();
      let url = '';
      if(id == 1){
        url = "https://orders.me-itech.com/nmol分装订购表.xlsx?v="+v;
      }else if(id == 2){
        url = "https://orders.me-itech.com/OD分装订购表.xlsx?v="+v;
      }

      // let url = "https://orders.me-itech.com/引物合成订购表.xlsx?v="+v;
      // let url = "https://orders.me-itech.com/nmol分装订购表.xlsx?v="+v;
      // let url = "https://orders.me-itech.com/OD分装订购表.xlsx?v="+v;
      window.location.href = url
    },
    onExceed() {
      this.$message({
        message: '一个订单只能上传一个文件，重新上传请先删除',
        type: 'error',
      });
    },
    onRemove() {
      this.file = ''
      this.stepsActive = 0
    },
    onSuccess(file) {
      if (file.code == 200) {
        this.file = file.data.p
        // this.alert = true
        this.stepsActive = 1
      } else {
        this.$message.error(file.message);
        this.$refs.updateFile.clearFiles();
      }
    },
    submit() {
      let data = {
        'file': this.file,
        'addressId':this.addressId
      }
      createOrders(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '提交成功'
          });
          this.$router.push({ name: 'ordersList'})
        } else {
          this.$message.error(res.message);
        }
      })
    },
    changeAddress(e){
      console.log(this.addressId)
      if(e == -1){
        this.newAddress = true
      }else{
        this.newAddress = false
      }
    },
    
  }
};
</script>
<style lang="less" scoped>
.upload {
  margin: 50px;
  display: inline-block;
}

.download {
  float: right;
  margin: 30px 40px;
  position: relative;
}
.download-icon{
  position: absolute;
  top:80px;
  left:60px;
  text-align: center;
  animation: jump 1s infinite;
}
.download ::placeholder{
  color: #409EFF; /* 修改为你想要的颜色 */
}

@keyframes jump {
  0% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-20px);
  }
  100% {
      transform: translateY(0);
  }
}
.newAddress{
  padding: 10px;
}
.el-radio{
  display: block;
  margin-bottom: 10px;
}
.submit {
  display: block;
  margin-left: 845px !important;
}
</style>