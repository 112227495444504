<template>
    <div>
        <el-form ref="form" :model="form" label-width="150px">
            <el-form-item label="所在地区">
              <el-select v-model="form.provinceValue" @change="clickProvince" placeholder="请选择省份" filterable >
                <el-option :label="item.name" :value="item.id" v-for="(item,index) in province" :key="index"></el-option>
              </el-select>
              <el-select v-model="form.cityValue" placeholder="请选择城市" @change="clickCity" filterable>
                <el-option :label="item.name" :value="item.id" v-for="(item,index) in city" :key="index"></el-option>
              </el-select>
              <el-select v-model="form.districtValue" placeholder="请选择区县" @change="clickDistrict" filterable>
                <el-option :label="item.name" :value="item.id" v-for="(item,index) in district" :key="index"></el-option>
              </el-select>
              <el-select v-model="form.streetValue" placeholder="请选择街道" filterable>
                <el-option :label="item.name" :value="item.id" v-for="(item,index) in street" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="邮政编码">
                <el-input v-model="form.postCode"></el-input>
            </el-form-item>
            <el-form-item label="详细地址">
                <el-input type="textarea" v-model="form.address"></el-input>
            </el-form-item>
            <el-form-item label="收货人姓名">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
                <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="电话号码">
                <el-input v-model="form.telephone"></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-switch v-model="form.default" active-text="设为默认收货地址"></el-switch>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">提交</el-button>
              </el-form-item>
          </el-form>
    </div>
</template>
<script>
import { getRegions,operationAddress } from "@/api/api.js";
export default {
    name: "regions",
    props: {
      
    },
    data() {
      return {
        form: {
            cityValue:'',
            districtValue:'',
            provinceValue:'',
            streetValue:'',
            postCode:'',
            address:'',
            name:'',
            phone:'',
            telephone:'',
            default:true
        },
        province:[],
        city:[],
        district:[],
        street:[],
        fourth:true,
        id:0
      }
    },
    created() {
        this.getRegions()
    },
    methods: {
      onSubmit() {
        console.log(this.form);
        console.log(this.fourth);
        if(this.fourth == true && this.form.streetValue == ''){
            this.$message.error('请选择区县/街道');
        }else if(this.fourth == false && this.form.districtValue == ''){
            this.$message.error('请选择区县/街道');
        }
        let data = this.form
        data.id = this.id
        operationAddress(data).then(res => {
          if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '提交成功'
          });
          this.$emit('handleChange',res.data.id) // 触发父组件中handleChange事件
        } else {
          this.$message.error(res.message);
        }
        })
      },
      clickProvince(id){
        this.getRegions(id,'city')
        this.city=[]
        this.district=[]
        this.street=[]
        this.form.cityValue=''
        this.form.districtValue=''
        this.form.streetValue=''
      },
      clickCity(id){
        this.getRegions(id,'district')
      },
      clickDistrict(id){
        this.getRegions(id,'street')
      },
      getRegions(pid,type='province'){
        let data = {pid:pid}
        getRegions(data).then(res => {
            switch(type){
                case 'province':
                    this.province = res.data
                    break;
                case 'city':
                    this.city = res.data
                    break;
                case 'district':
                    this.district = res.data
                    break;
                case 'street':
                    if(res.data.length <= 0){
                        this.fourth = false
                    }else{
                        this.fourth = true
                    }
                    this.street = res.data
                    break;
            }
        })
        
      }
    }
}
</script>

<style lang="less" scoped>
    .el-form-item{
        margin-bottom: 5px;
    }
    .el-select{
        margin-right: 5px;
    }
</style>
