import axios from 'axios'
import { Message, Loading } from 'element-ui'
const ConfigBaseURL = 'https://api.me-itech.com' //默认路径，这里也可以使用env来判断环境
let loadingInstance = null //这里是loading

export const Service = axios.create({
  timeout: 7000, // 请求超时时间
  baseURL: ConfigBaseURL,
  method: 'post',
  headers: {
    'Authorization': localStorage.getItem('token')
  },
})
// 添加请求拦截器
Service.interceptors.request.use(config => {
  loadingInstance = Loading.service({
    lock: true,
    text: 'loading...'
  })
  return config
})
// 添加响应拦截器
Service.interceptors.response.use(response => {
  loadingInstance.close()
  console.log(response.request.responseURL)
  if(localStorage.getItem('token') == null){
    if (!response.request.responseURL.includes("get_yzm")){
      location.reload()
    }
    
  }
  console.log(response.data)
  if(response.data.code == 40000 && response.data.message.indexOf("token") !== -1){

      Message({
        message: '长时间未操作，请重新登录',
        type: 'error',
        duration: 1 * 1000,
        onClose:function(){
          console.log(11111)
          localStorage.removeItem('token')
          location.reload()
        }
      })

    }
  return response.data
}, error => {
  console.log('TCL: error', error)
  const msg = error.Message !== undefined ? error.Message : ''
  Message({
    message: '网络错误' + msg,
    type: 'error',
    duration: 1 * 1000
  })
  loadingInstance.close()
})