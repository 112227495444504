<template>
  <div>
    <breadcrumbVue :breadcrumb="breadcrumb" :info="info"></breadcrumbVue>
    <div class="main">
      <mainLeft></mainLeft>
      <div class="mainRight">
        <regions ref="regions" @handleChange="changeAddress"></regions>
        <el-divider content-position="left">已保存的收货地址</el-divider>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="name" label="收货人姓名" width="120"> </el-table-column>
          <el-table-column prop="phone" label="手机号/电话号码" width="150"> </el-table-column>
          <el-table-column prop="provinceName" label="省份" width="120"> </el-table-column>
          <el-table-column prop="cityName" label="市区" width="120"> </el-table-column>
          <el-table-column prop="address" label="详细地址" width="300"> </el-table-column>
          <el-table-column prop="postCode" label="邮编" width="120"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="170">
            <template slot-scope="scope">
              <el-button @click="doUpdate(scope.row)" type="text" size="small" >修改</el-button>
              <el-button @click="doDelete(scope.row,scope.$index)" type="text" size="small" >删除</el-button>
              <el-button @click="doDefault(scope.row,scope.$index)" type="text" size="small" v-if="scope.row.default==0">设为默认地址</el-button>
              <el-tag type="danger" disable-transitions v-if="scope.row.default==1" style="margin-left:10px">默认地址</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import mainLeft from '../mainLeft.vue';
import regions from '@/components/regions.vue'
import breadcrumbVue from '@/components/breadcrumb.vue';
import {addressList,addressDelete,addressDefault} from '@/api/api.js'
export default {
  data() {
    return {
      breadcrumb: [
        {
          name: '地址管理'
        }
      ],
      tableData: [],
      info:[]
    };
  },
  components: {
    mainLeft,
    regions,
    breadcrumbVue
  },
  created(){
    this.getList();
  },
  methods:{
    changeAddress(){
      this.getList();
    },
    getList(){
      addressList().then(res =>{
        this.tableData = res.data
      })
    },
    doUpdate(e){
      this.$emit('toUpdate',e)
      let form = e
      form.default = form.default == 1 ? true : false
      form.cityValue = form.cityName
      form.districtValue = form.districtName
      form.provinceValue = form.provinceName
      form.streetValue = form.streetName
      this.$refs.regions.form = form
      this.$refs.regions.id = form.id
    },
    doDelete(e,index){
      addressDelete({id:e.id}).then(res =>{
        if(res.code == 200){
            this.$message({
                type: 'success',
                message: '删除成功'
            });
            this.tableData.splice(index,1)
        }else{
            this.$message.error(res.message);
        }
      })
    },
    doDefault(e,index){
      addressDefault({id:e.id}).then(res =>{
        if(res.code == 200){
            this.$message({
                type: 'success',
                message: '修改成功'
            });
            this.tableData.forEach((item) =>{
              item.default = 0
              return item
            })
            this.tableData[index].default = 1
        }else{
            this.$message.error(res.message);
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>

</style>