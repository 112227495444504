<template>
  <div id="app">
    <div class="header" v-if="$route.meta.isShowHeader">
      <div class="headerCentre">
        <el-avatar shape="square" :size="100" fit="contain" :src="logo" class="headerLogo"></el-avatar>
        <span class="headerTitle">铭毅智造科技有限公司</span>
        <el-link type="danger" class="logout" @click="logout">退出登录</el-link>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
  import logo from "@/assets/logo.png";
  export default {
    data() {
      return {
        // fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
        logo: logo,
      }
    },
    methods:{
      logout(){
        localStorage.removeItem('token')
        this.$router.push({name:'login'});
      }
    }
  }
</script>

<style lang="less">

html,body,#app{
  height:100%;
  background-color: #f5f5f5;
}
.main{
  width:1200px;
  margin:0 auto;
}
.header{
    height:100px;
    background:url('@/assets/headerBg.png');
    display:flex;
    justify-content:center;
    align-items:center;
    .headerCentre{
        height:100px;
        width:1200px;
        .headerLogo{
          float:left;
          background-color: transparent;
        }
        .headerTitle{
          font-size: 40px;
          line-height: 100px;
          float: left;
          margin-left: 50px;
          color: #49c5d5;
        }
        .logout{
          float: right;
          margin:10px 10px;
        }
    }
}
</style>
