import { Service } from './axios.js'

export function userRegister(data){
    return Service({
        url: '/register',
        mothod: 'post',
        data:data
    })
}


export function userLogin(data){
    return Service({
        url: '/login',
        mothod: 'post',
        data:data
    })
}

export function getSms(data){
    return Service({
        url: '/get_yzm',
        mothod: 'post',
        data:data
    })
}

export function smsLogin(data){
    return Service({
        url: '/smsLogin',
        mothod: 'post',
        data:data
    })
}


export function createOrders(data){
    return Service({
        url: '/createOrders',
        mothod: 'post',
        data:data
    })
}

export function getRegions(data){
    return Service({
        url: '/regions',
        mothod: 'post',
        data:data
    })
}

export function operationAddress(data){
    return Service({
        url: '/operationAddress',
        mothod: 'post',
        data:data
    })
}

export function addressList(data){
    return Service({
        url: '/addressList',
        mothod: 'post',
        data:data
    })
}

export function addressDelete(data){
    return Service({
        url: '/addressDelete',
        mothod: 'post',
        data:data
    })
}

export function addressDefault(data){
    return Service({
        url: '/addressDefault',
        mothod: 'post',
        data:data
    })
}

export function ordersGroupList(data){
    return Service({
        url: '/ordersGroupList',
        mothod: 'post',
        data:data
    })
}

export function ordersList(data){
    return Service({
        url: '/ordersList',
        mothod: 'post',
        data:data
    })
}

export function ordersAddress(data){
    return Service({
        url: '/ordersAddress',
        mothod: 'post',
        data:data
    })
}

export function userInfo(data){
    return Service({
        url: '/userInfo',
        mothod: 'post',
        data:data
    })
}

export function userInfoUpdate(data){
    return Service({
        url: '/userInfoUpdate',
        mothod: 'post',
        data:data
    })
}
