<template>
    <div class="heightBreadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>铭毅智造</el-breadcrumb-item>
            <el-breadcrumb-item v-for="(item,index) in breadcrumb" :key="index">{{item.name}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
export default {
    name: "breadcrumb",
    props: {
      breadcrumb: Array,
    },
    data() {
        return {

        }
    },
    created(){
        
    },
    methods: {
        
    }
}
</script>

<style lang="less" scoped>
    .heightBreadcrumb{
        background-color: #fff;
        width: 100%;
        
        .el-breadcrumb{
          margin:0 auto;
          width:1200px;
          line-height: 50px;
        }
      }
</style>
