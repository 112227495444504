<template>
  <div>
    <breadcrumbVue :breadcrumb="breadcrumb"></breadcrumbVue>
    <div class="main">
      <mainLeft></mainLeft>
      <div class="mainRight">
        <el-row>
          <el-col :span="6">
            <el-upload class="avatar-uploader" :action="action" :headers="headers"
              :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-s-custom avatar-uploader-icon"></i>
            </el-upload>
          </el-col>
          <el-col :span="18">
            <el-divider content-position="left">账户信息</el-divider>
            <el-form label-position="right" label-width="80px" :model="formLabelAlign">
              <el-form-item label="铭毅ID">
                <el-input v-model="uuid" disabled></el-input>
              </el-form-item>
              <el-form-item label="真实姓名">
                <el-input v-model="formLabelAlign.name"></el-input>
              </el-form-item>
              <el-form-item label="联系电话">
                <el-input v-model="formLabelAlign.contact_phone"></el-input>
              </el-form-item>
              <el-form-item label="邮箱">
                <el-input v-model="formLabelAlign.mail"></el-input>
              </el-form-item>
              <el-form-item label="地址">
                <el-input type="textarea" v-model="formLabelAlign.address"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm()">提交</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>

    </div>
  </div>

</template>
<script>
import mainLeft from '../mainLeft.vue';
import breadcrumbVue from '@/components/breadcrumb.vue';
import { 
  userInfoUpdate,
  userInfo 
} from "@/api/api.js";
export default {
  data() {
    return {
      breadcrumb: [
        {
          name: '账户信息'
        },
      ],
      formLabelAlign: {},
        action: this.BaseUrl + '/userHead',
        headers: { 'Authorization': localStorage.getItem('token') },
        uuid:'',
      imageUrl: ''
    };
  },
  components: {
    mainLeft,
    breadcrumbVue
  },
  created(){
    this.getUserInfo();
  },
  methods: {
    submitForm(){
      
      let data = {
        'name':this.formLabelAlign.name,
        'contact_phone':this.formLabelAlign.contact_phone,
        'mail':this.formLabelAlign.mail,
        'address':this.formLabelAlign.address
      }
      userInfoUpdate(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '提交成功'
          });
        } else {
          this.$message.error(res.message);
        }
      })
    },
    getUserInfo(){
      userInfo().then(res =>{
        console.log(res)
        this.formLabelAlign = res.data
        this.uuid = res.data.uuid
        this.imageUrl = res.data.head
      })
    },
    handleAvatarSuccess(res, file) {
      console.log(res,'成功')
      if(res.code == 200){
        this.imageUrl = URL.createObjectURL(file.raw);
      }else{
        this.$message.error(res.message);
      }
      
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }
  }
};
</script>
<style lang="less" scoped>
.avatar-uploader{
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
  margin:30px
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 100px;
  color: #dadada;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-input{
  width: 300px;
}
</style>