<template>
  <div>
    <breadcrumbVue :breadcrumb="breadcrumb"></breadcrumbVue>

    <div class="main">
      <mainLeft></mainLeft>
      <div class="mainRight">
        <!-- <div>
          <div class="demo-input-suffix">
            订单编号：
            <el-input placeholder="订单编号" v-model="order_sn"></el-input>
          </div>
        </div> -->
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="order_sn" label="订单编号" width="180"></el-table-column>
          <el-table-column prop="childNumber" label="引物条数"> </el-table-column>
          <el-table-column prop="freight" label="运费"> </el-table-column>
          <el-table-column prop="price" label="金额"> </el-table-column>
          <el-table-column prop="time" label="创建日期"> </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-tag :type="scope.row.statusId == -1 ? 'info' : (scope.row.statusId == 0 ? 'danger' : (scope.row.statusId == 1 ? 'success' : (scope.row.statusId == 2 ? 'primary' : '')))" disable-transitions>{{scope.row.status}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="pay_type" label="付款方式"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row.id)" type="text" size="small">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next" :total="count" @current-change="changePage" hide-on-single-page></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import mainLeft from '../mainLeft.vue';
import breadcrumbVue from '@/components/breadcrumb.vue';
import { ordersGroupList } from '@/api/api.js'
export default {
  data() {
    return {
      breadcrumb: [
        {
          name: '合成订单'
        }
      ],
      page:1,
      limit:2,
      status:'',
      order_sn:'',
      count:0,
      tableData: []
    };
  },
  created(){
    this.getList()
  },
  components: {
    mainLeft,
    breadcrumbVue
  },
  methods:{
    getList(){
      let data = {
        'page': this.page,
        'status': this.status,
        'order_sn': this.order_sn,
      }
      ordersGroupList(data).then(res => {
        this.tableData = res.data.list
        this.count = res.data.count
      })
    },
    changePage(page){
      this.page = page
      this.getList()
    },
    handleClick(e){
      this.$router.push({name:'ordersInfo',query: {sn:e}})
    }
  }
};
</script>
<style lang="less" scoped>
.main {
  margin-top: 20px;
}
</style>