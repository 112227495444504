<template>
  <div class="mainLeft">
    <div v-for="(v,i) in listArray" :key="i" class = "children">
      <list :list="v.children" :title="v.name" ></list>
    </div>
  </div>
</template>
<script>
import list from '@/components/list.vue'
export default {
  name: "mainLeft",
  components: {
    list
  },
  data() {
    return {
      listArray:[
        {
          "name":"用户管理",
          children:[
            {
              "route": "index",
              "name": "DNA合成"
            },
            {
              "route": "ordersList",
              "name": "合成订单"
            },
            {
              "route": "userInfo",
              "name": "账户信息"
            },
            {
              "route": "addressList",
              "name": "地址管理"
            },
          ]
        },
      ],
    }
  },

}
</script>

<style lang="less" scoped>
  .mainLeft {
    width: 168px;
    float:left;
    .children{
      border:1px solid #c1c1c1;
      border-radius: 4px;
      padding: 0 10px;
      background-color: #fff;
      color: #434343;
      margin-bottom: 10px;
    }
  }

</style>