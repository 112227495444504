<template>
  <div class="login_container">
    <div class="login_box">
      <div class="avatar_box">
        <img src="@/assets/logo.png" alt="">
      </div>
      <el-form label-width="0px" class="login_in" ref="loginForm" :model="loginForm" v-if='login'>
        <el-form-item style="text-align: center;">
          <span style="font-size:24px">登录</span>
        </el-form-item>
        <el-form-item prop="userName" >
          <el-input type="text" prefix-icon="el-icon-user" v-model="loginForm.userName" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item prop="passWord">
          <el-input type="password" prefix-icon="el-icon-thumb" v-model="loginForm.passWord"  placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="submitForm('loginForm')">登录</el-button>
          <el-button @click="register">注册</el-button>
          <el-button type="success" @click="toPhoneLogin">验证码登录</el-button>
        </el-form-item>
      </el-form>
      <el-form label-width="0px" class="login_in" ref="registerForm" :model="registerForm" v-if='registers'>
        <el-form-item prop="userName" >
          <el-input type="text" prefix-icon="el-icon-user" v-model="registerForm.userName" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item prop="passWord">
          <el-input type="password" prefix-icon="el-icon-thumb" v-model="registerForm.passWord" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item prop="passWord">
          <el-input type="password" prefix-icon="el-icon-thumb" v-model="registerForm.againPassWord" placeholder="请再一次输入密码"></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="submitRegisterForm('registerForm')">注册</el-button>
          <el-button @click="toLogin">密码登录</el-button>
          <el-button type="success" @click="toPhoneLogin">验证码登录</el-button>
        </el-form-item>
      </el-form>
      <el-form label-width="0px" class="login_in" ref="loginPhoneForm" :model="loginPhoneForm" v-if='phonelogin'>
        <el-form-item style="text-align: center;">
          <span style="font-size:24px">验证码登录</span>
        </el-form-item>
        <el-form-item prop="userName" >
          <el-input type="text" prefix-icon="el-icon-user" v-model="loginPhoneForm.userName" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="passWord">
          <el-input type="password" prefix-icon="el-icon-thumb" v-model="loginPhoneForm.passWord"  placeholder="请输入验证码" style="width:296px"></el-input>
          <el-button @click="getyzm()">获取验证码</el-button>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="submitLoginPhoneForm('registerForm')">登录</el-button>
          <el-button @click="toLogin">密码登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { 
  userLogin,
  userRegister,
  getSms,
  smsLogin
} from "@/api/api.js";
export default {
    name: "Login",
    data(){
        return {
            loginForm:{
                userName:'',
                passWord:''
            },
            registerForm:{},
            loginPhoneForm:{},
            login:true,
            registers:false,
            phonelogin:false,
        }
    },
    methods:{
        submitForm(formName) {
          let that = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let data = {
                        'phone': this.loginForm.userName,
                        'password': this.loginForm.passWord
                    }
                    userLogin(data).then(res => {
                        console.log(res)
                        if(res.code == 200){
                          localStorage.setItem('token',res.data.token);
                            this.$message({
                                type: 'success',
                                message: '登录成功',
                                onClose(){
                                  that.$router.replace({path:'/'});
                                }
                            });
                            
                        }else{
                            this.$message.error(res.message);
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        submitLoginPhoneForm(){
          if(!this.loginPhoneForm.userName || this.loginPhoneForm.userName == ''){
            this.$message.error('请输入手机号');
            return false;
          }
          if(!this.loginPhoneForm.passWord || this.loginPhoneForm.passWord == ''){
            this.$message.error('请输入验证码');
            return false;
          }
          let data = {
              'phone': this.loginPhoneForm.userName,
              'password': this.loginPhoneForm.passWord
          }
          let that = this
          smsLogin(data).then(res => {
              console.log(res)
              if(res.code == 200){
                localStorage.setItem('token',res.data.token);
                  this.$message({
                      type: 'success',
                      message: '登录成功',
                      onClose(){
                        that.$router.replace({path:'/'});
                      }
                  });
              }else{
                  this.$message.error(res.message);
              }
          })

        },
        getyzm(){
          let phone = this.loginPhoneForm.userName
          if(!this.isCellPhone(phone)){
            this.$message.error('请输入正确的手机号');
            return false;
          }
          getSms({phone:phone}).then(res => {
              console.log(res)
              if(res.code == 200){
                  this.$message({
                      type: 'success',
                      message: '发送成功'
                  });
              }else{
                  this.$message.error(res.message);
              }
          })
        },
        submitRegisterForm(){
          console.log(this.registerForm)
          if(!this.registerForm.userName || this.registerForm.userName == ''){
            this.$message.error('请输入用户名');
            return false;
          }
          if(this.registerForm.passWord.length < 8){
            this.$message.error('密码长度不得少于 8 位');
            return false;
          }
          if(!this.registerForm.passWord || this.registerForm.passWord == ''){
            this.$message.error('请输入密码');
            return false;
          }
          if(this.registerForm.passWord != this.registerForm.againPassWord){
            this.$message.error('两次密码不一致');
            return false;
          }
                  
          
            let data = {
                'phone': this.registerForm.userName,
                'password': this.registerForm.passWord,
                'againPassword': this.registerForm.againPassWord
            }
            
            userRegister(data).then(res => {
                console.log(res)
                if(res.code == 200){
                    localStorage.setItem('token',res.data.token);
                    this.$router.replace({path:'/'});
                    this.$message({
                        type: 'success',
                        message: '注册成功'
                    });
                }else{
                    this.$message.error(res.message);
                }
            })
               
        },
        register(){
          this.login=false
          this.registers=true,
          this.phonelogin=false
        },
        toLogin(){
          this.login=true
          this.registers=false,
          this.phonelogin=false
        },
        toPhoneLogin(){
          let that = this
          that.login=false
          that.registers=false
          that.phonelogin=true
        },
        isCellPhone(val) {
            if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(val)) {
                return false;
            } else {
                return true;
            }
        }

    }
}
</script>

<style scoped lang="less">
  .login_container {
    
    background: url('@/assets/bg.png');
    background-size: 100% 100%;
    height: 100%;
  }

  .login_box {
    width: 450px;
    height: 355px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .avatar_box {
      height: 130px;
      width: 130px;
      border: 1px solid #eee;
      border-radius: 50%;
      box-shadow: 0 0 10px #ddd;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      padding: 10px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #eee;
      }
    }
  }

  .btns {
    display: flex;
    justify-content: flex-end;

  }

  .login_in {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
</style>