<template>
    <div>
        <div class="title">
            {{title}}
        </div>
        <ul class="list">
            <li v-for="(v,i) in list" :key="i" @click="routeLink(v.route)">{{v.name}}</li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "list",
    props: {
        list: Array,
        title: String
    },
    data() {
        return {

        }
    },
    methods: {
        routeLink(e) {
            if (this.$route.name !== e) {
                this.$router.push({ name: e })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.title {
    border-bottom: 1px dotted #e2e2e2;
    color: #14407b;
    line-height: 32px;
    font-size: 16px;
}

.list {
    li {
        line-height: 30px;
        font-size: 14px;
        cursor: pointer;
    };
    li:hover{
        color: red;
    }
}
</style>
