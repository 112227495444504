<template>
  <div>
    <breadcrumbVue :breadcrumb="breadcrumb"></breadcrumbVue>
  
    <div class="main">
      <mainLeft></mainLeft>
      <div class="mainRight">
        <el-divider content-position="left">DNA合成</el-divider>
        <el-steps :active="stepsActive" align-center>
          <el-step title="上传表格" description="请先下载「引物合成订购表」填写之后上传"></el-step>
          <el-step title="提交订单" description="点击下方提交按钮提交订单"></el-step>
          <el-step title="解析表格" description="「引物合成订购表」解析中"></el-step>
          <el-step title="完成" description="「引物合成订购表」解析完成" v-if="this.status != -1"></el-step>
          <el-step title="失败" description="「引物合成订购表」解析失败,请检查表格重新提交订单" status="error" v-if="this.status == -1"></el-step>
          <el-step title="等待审核" description="等待审核" v-if="this.status != -1"></el-step>
          <el-step title="审核通过" description="审核通过" v-if="this.status != -1 && this.status != -2"></el-step>
          <el-step title="审核拒绝" description="审核拒绝" v-if="this.status == -2" status="error"></el-step>
        </el-steps>
        <template v-if="status > 0">
          <el-descriptions title="收货信息" v-if="status > 1">
            <el-descriptions-item label="订单号">{{this.order_sn}}</el-descriptions-item>
            <el-descriptions-item label="物流单号">{{this.express}}</el-descriptions-item>
            <el-descriptions-item label="姓名">{{this.ordersAddress.name}}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{this.ordersAddress.phone}}</el-descriptions-item>
            <el-descriptions-item label="居住地">{{this.ordersAddress.provinceName}}{{this.ordersAddress.cityName}}{{this.ordersAddress.districtName}}{{this.ordersAddress.streetName}}</el-descriptions-item>
            <el-descriptions-item label="联系地址">{{this.ordersAddress.address}}</el-descriptions-item>
          </el-descriptions>
          <el-table :data="tableData" border style="width: 100%">
            <!-- <el-table-column type="expand">
              <template slot-scope="props">
                {{props.row}}
              </template>
            </el-table-column> -->
            <el-table-column prop="primer" label="Primer名称" width="120"></el-table-column>
            <el-table-column prop="sequence" label="序列" width="240"> </el-table-column>
            <el-table-column prop="alkali_number" label="碱基数"> </el-table-column>
            <el-table-column prop="purified" label="纯化方式"> </el-table-column>
            <el-table-column prop="od" label="总OD"> </el-table-column>
            <el-table-column prop="number" label="管数"> </el-table-column>
            <el-table-column prop="od_number" label="OD/管"> </el-table-column>
            <el-table-column prop="nmloes" label="nmloes"> </el-table-column>
            <el-table-column prop="decorate" label="修饰"> </el-table-column>
            <el-table-column prop="price" label="价格"> </el-table-column>
            <!-- <el-table-column fixed="right" label="操作" width="100">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row.order_sn)" type="text" size="small">查看</el-button>
              </template>
            </el-table-column> -->
          </el-table>
        </template>
        <el-pagination background layout="prev, pager, next" :total="count" @current-change="changePage" hide-on-single-page>
      </el-pagination>
      </div>
    </div>
    </div>
  </template>
  <script>
  import mainLeft from '../mainLeft.vue';
import breadcrumbVue from '@/components/breadcrumb.vue';
import { ordersList,ordersAddress } from '@/api/api.js'
    export default {
      data() {
        return {
          order_sn:'',
          status:1,
          stepsActive: 4,
          express:'',
          breadcrumb: [
            {
              name: '合成订单'
            },
            {
              name: '订单详情'
            }
          ],
          page:1,
          count:0,
          tableData: [],
          ordersAddress
        };
      },
      components:{
        mainLeft,
        breadcrumbVue
      },
      created(){
        console.log(this.$route.query.sn)
        if(this.$route.query.sn == undefined || this.$route.query.sn == ''){
          this.$message.error('订单号不存在');
          this.$router.push({ name: 'ordersList'})
        }
        this.order_sn = this.$route.query.sn
        this.getList()
        this.getAddress()
      },
      methods:{
        getList(){
          let data = {
            'page': this.page,
            'order_sn': this.order_sn,
          }
          ordersList(data).then(res => {
            console.log(res)
            this.tableData = res.data.list
            this.count = res.data.count
          })
        },
        getAddress(){
          ordersAddress({order_sn:this.order_sn}).then(res => {
            this.ordersAddress = res.data
            this.status = res.data.status
            this.express = res.data.express
            if(this.status == 1){
              this.stepsActive = 5
            }else if(this.status == 2){
              this.stepsActive = 6
            }else if(this.status == 0){
              this.stepsActive = 3
            }
          })
        },
        changePage(page){
          this.page = page
          this.getList()
        }
      }
    };
  </script>
  <style lang="less" scoped>
    .main{
      margin-top: 20px;
    }
  </style>